import { Helmet } from 'react-helmet';

function EventCal() {
  const title = 'Event Calendar (Coming soon)';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title}</h1>
        </div>
        <p>
          Stay up-to-date with our curated list of upcoming events. From themed nights to member-only gatherings, never miss out on the action. Add events to your personal calendar and receive real-time notifications for event reminders and promotional offers.
        </p>
        <div style={
          {
            position: 'relative', paddingBottom: '75%', height: 0, overflow: 'hidden',
          }}>
          <iframe
            src="https://calendar.google.com/calendar/embed?src=your_calendar_id%40group.calendar.google.com&ctz=America%2FNew_York"
            style={
              {
                position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0,
              }}
            frameBorder="0"
            scrolling="no"
            title="Google Calendar"
          ></iframe>
        </div>
      </div>
      <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
    </>
  );
}

export default EventCal;
