import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './btn.css';

function ComingEvent() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Upcoming Events</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-5 mt-5 row">
          <h1 className="display-5 fw-bold py-4 text-chaman">Upcoming Event</h1>
      </div>
      <div className="px-4 pt-1 mt-2 row">
          <div className='col-md-5 col-12 px-md-4 px-sm-1 py-4 rounded-3 bg-tr-dark order-2 order-md-1'>

            <p className="lead mb-4 fs-5 text-center text-md-start text-white">
              As a member of our prestigious high-class club, you can look forward to a wide array of exclusive upcoming events designed to offer unparalleled luxury and sophistication. From elegant soirées and private dining experiences to exclusive networking events and high-profile gatherings, our club offers a perfect setting for you to indulge in the finer things in life. Your VIP membership ensures you have priority access to all these carefully curated events, allowing you to enjoy an elite experience that matches your status. Join us in celebrating the elegance and distinction that only our club can provide.
            </p>

          </div>
          <div className='col-md-7 col-12 order-1 order-md-2 p-0 px-md-2 mb-md-0 mb-2'>
            <img src="/images/party.jpg" alt="coming-event" className='img-fluid rounded-3' />
          </div>
        </div>
        <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default ComingEvent;
