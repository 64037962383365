import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Link, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form';
import {
  Col, Button, Form, FormControl, InputGroup, FormLabel, Spinner,
} from 'react-bootstrap';
import { createCheckoutSession } from '../services/PaymentService';
import useAuth from '../hooks/useAuth';

function Buy() {
  const title = 'Token Purchase Platform';
  const { getSession } = useAuth();
  const user = getSession();
  const navigate = useNavigate();
  const { paymentStatus } = useParams();
  const [unitPrice, setUnitPrice] = useState(1);
  const [quantity, setQuantity] = useState(1000);
  const [wallet, setWallet] = useState('');
  const [checkOutURL, setCheckOutURL] = useState(null);
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();
  const vaildationRule = {
    walletAddressLen: 42,
    minQty: 1000,
  };

  const handlePayment = async (data) => {
    let response;
    try {
      let fullURL = '';
      if (window) {
        fullURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
      }

      response = await createCheckoutSession({
        quantity: data.quantity, // Number of BB Tokens
        email: user.email, // Customer email
        walletAddress: data.wallet, // Customer wallet address
        success_url: `${fullURL}/console/buy/success`, // Success URL
        cancel_url: `${fullURL}/console/buy/incomplete`, // Cancel URL
      });

      // Redirect to Stripe Checkout page
      if (response) {
        setCheckOutURL(response.url)
        setQuantity(data.quantity)
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  useEffect(() => {
    if (checkOutURL) {
      // Show the confirmation dialog using Swal
      Swal.fire({
        title: 'Confirm Purchase',
        text: `You are about to purchase ${quantity} TBT`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Redirect user to Stripe checkout page
            window.location.href = checkOutURL;
          } catch (error) {
            Swal.fire('Error', 'Something went wrong with the payment. Please try again.', 'error');
          }
        } else {
          setCheckOutURL(null)
        }
      });
    }
  }, [checkOutURL]);
  if (!paymentStatus) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="container-fluid">
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{title}</h1>
          </div>
          <p>
            Easily purchase BBoss tokens through our user-friendly interface. Choose from multiple payment options, including credit cards and cryptocurrency. Enjoy seamless transactions and start accessing exclusive club benefits today.
            <br />
            <Form className='card card-body my-3 col-12 col-md-6' style={{ minWidth: '320px' }} >
              <div className='row'>
                <div className='col-3 col-md-2 position-relative'>
                  <img src="https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f676174657761792e697279732e78797a2f5f49554d753043386e6c5048675156534375316f674d3474656a786b44366c3970707a70414d4f63476445"
                    className='rounded position-absolute top-50 start-50 translate-middle' />
                </div>
                <div className='col'>
                  <span>Token Price</span><br />
                  <span style={{ fontSize: '24px' }} >{unitPrice} HKD</span>
                </div>
                <Form.Group as={Col} controlId="inputQuantity">
                  <FormLabel>Quantity</FormLabel>
                  <FormControl type="number"
                    isInvalid={errors.quantity}
                    placeholder="Quantity"
                    defaultValue={vaildationRule.minQty}
                    {
                    ...register('quantity', {
                      required: true,
                      min: vaildationRule.minQty,
                    })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.quantity?.type === 'required' && 'Quantity is required'}
                    {errors.quantity?.type === 'min' && `Minimum quantity is ${vaildationRule.minQty}`}
                  </Form.Control.Feedback>
                </Form.Group>

              </div>
              <div className='row'>

                <Form.Group as={Col} controlId="inputWallet" className='offset-3 offset-md-2'>
                  <FormLabel>Wallet Address</FormLabel>
                  <FormControl type="text"
                    isInvalid={errors.wallet}
                    placeholder="Wallet Address"
                    {
                    ...register('wallet', {
                      required: true,
                      minLength: vaildationRule.walletAddressLen,
                    })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.wallet?.type === 'required' && 'Wallet Address is required'}
                    {errors.wallet?.type === 'minLength' && 'Invaild Wallet Address'}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Check
                    label="I agree to receive any marketing promotional materials from Big Boss Generation."
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    isInvalid={errors.agree}
                    {...register('agree', { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Check
                    label={<>I hereby confirmed that I have read, understand and agree to the&nbsp;
                      <a href="/tnc/Terms-of-Use" target="_blank">Terms of Use</a>,&nbsp;
                      <a href="/tnc/Disclaimer" target="_blank">Disclaimer</a> and&nbsp;
                      <a href="/tnc/Privacy-Policy" target="_blank">Privacy Policy</a>.</>}
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    isInvalid={errors.agreeTnC}
                    {...register('agreeTnC', { required: true })}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Check
                    label="I understand that TBT is the utility token used as the medium of consumption throughout the Big Boss Generation ecosystem."
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    isInvalid={errors.understand}
                    {...register('understand', { required: true })}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Check
                    label="I understand and agree that my purchase of TBT should not be considered as an investment."
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    isInvalid={errors.investment}
                    {...register('investment', { required: true })}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Check
                    label="I hereby agree that my purchase of TBT complies with applicable laws and regulations in my jurisdiction."
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    isInvalid={errors.hereby}
                    {...register('hereby', { required: true })}
                  />
                </Form.Group>

              </div>
            </Form>
            <div className='card card-body bg-light col-12 col-md-6'>
              <span className='text-nowrap'><i className="bi bi-exclamation-diamond-fill text-danger"></i>&nbsp;Note:​A processing fee may be charged </span>by the payment gateway.
              <br />
              You may not complete your purchase of TBT until the Know Your Client (“KYC”) process is completed.  Failure to complete the KYC check or failure to pass the KYC check may result in the rejection or cancellation of your purchase.  We reserve the right to charge an administration fee for such failure.
            </div>
            <button className='button-gold my-4 px-3 py-2 rounded-pill'
              onClick={handleSubmit(handlePayment)}
            > Buy Now </button>
          </p>
        </div>
      </>
    );
  }

  if (paymentStatus === 'success') {
    return (<>
      <Helmet>
        <title>{title} - Success</title>
      </Helmet>
      <div className="container-fluid">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title}</h1>
        </div>
        <p className='text-center' style={{ fontSize: '128px' }}>
          <i className={'bi bi-check-circle text-success'} />
        </p>
        <p>
          <div className='card card-body bg-light border-0'>
            Thank you for your purchase! Your payment has been successfully processed, and you are now the proud owner of Big Boss Tokens. An email confirmation with the transaction details has been sent to your registered email address. If you have any questions or require further assistance, please don’t hesitate to contact our support team. We appreciate your business and look forward to serving you again soon!
          </div>
          <button className='button-gold my-4 px-3 py-2 rounded-pill'
            onClick={() => navigate('/console/buy')}
          > Buy Again </button>
        </p>
      </div>
      <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
    </>)
  }

  if (paymentStatus === 'incomplete') {
    return (<>
      <Helmet>
        <title>{title} - Incomplete</title>
      </Helmet>
      <div className="container-fluid">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title}</h1>
        </div>
        <p className='text-center' style={{ fontSize: '128px' }}>
          <i className="bi bi-exclamation-diamond-fill text-danger"></i>
        </p>
        <p>
          <div className='card card-body bg-light border-0'>
            It looks like your payment couldn’t be completed at this time. Please double-check your payment details and try again. If the issue persists, ensure your payment method is valid or contact your financial provider. We apologize for the inconvenience and are here to assist you. Feel free to reach out to our support team for any help. Thank you for your patience, and we hope to complete your transaction soon!  <br />
          </div>

          <button className='button-gold my-4 px-3 py-2 rounded-pill'
            onClick={() => navigate('/console/buy')}
          > Try Again </button>
        </p>
      </div>
      <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
    </>)
  }
}

export default Buy;
