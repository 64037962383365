import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link, useLocation, useNavigate, useOutletContext,
} from 'react-router-dom';
import {
  Button, Col, Form, FormControl, FormLabel, Row, Spinner,
} from 'react-bootstrap';

import StatusAlert from '../components/StatusAlert';
import useAuth from '../hooks/useAuth';
import useForm from '../hooks/useForm';

import './auth.css';

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : '/console';
}

function Login() {
  const title = 'Login';
  const context = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const { login, getSession } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [user, setUser] = useState(getSession())

  useEffect(() => {
    if (user) {
      navigate(redirectPath(search));
    }
  }, [])

  const handleLogin = async (e, data) => {
    // eslint-disable-next-line no-console
    try {
      setIsLoading(true);
      const token = await login(data.email, data.password);
      // eslint-disable-next-line no-console
      console.log(`login successful, token: ${token}`);
      setIsLoading(false);
      navigate(redirectPath(search));
    } catch (err) {
      console.log('err', err)
      context.setAlertOpts({
        text: err.response?.data?.message ?? err.message,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  const validators = {
    email: {
      required: {
        value: true,
        message: 'Email is required',
      },
    },
    password: {
      required: {
        value: true,
        message: 'Password is required',
      },
    },
  };

  // Using a custom hook to show how we can build out our own hook.
  const {
    data, handleChange, handleSubmit, errors,
  } = useForm({
    onSubmit: handleLogin,
    validators,
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="text-center mt-5 p-5 fade-in">
        <div className="row">
          <div className="col-md-5 order-md-0 order-2">
            <img src="./images/loginSide.jpg" alt="logo" className="img-fluid rounded-3" />
          </div>
          <div className="col-md-1 col-sm-12 order-1"></div>
          <div className="col-md-3 col-sm-12 pt-md-5 pt-sm-0 pb-3 my-3 bg-tr-dark rounded-3 order-md-2 order-0">
            <Form noValidate>
              <p className="my-3 fw-normal d-none">
                Click <strong>Log in</strong> button to log into the admin console.
                Use <strong>admin</strong>:<strong>qwerty</strong> to log in.
              </p>
              <Form.Group className="form-floating" controlId="inputEmail">
                <FormControl type="text"
                  className="form-control form-input-top"
                  isInvalid={errors?.email}
                  placeholder="Email"
                  onChange={handleChange('email')}
                />
                <FormLabel>Email</FormLabel>
              </Form.Group>
              <Form.Group className="form-floating" controlId="inputPassword">
                <FormControl type="password"
                  className="form-control form-input-bottom"
                  isInvalid={errors?.password}
                  placeholder="Password"
                  onChange={handleChange('password')}
                />
                <FormLabel>Password</FormLabel>
              </Form.Group>
              <div>
                {Object.keys(errors).map((key) => <div className="text-danger" key={key}>{errors[key]}</div>)}
              </div>
              <Form.Group as={Row} className="my-3" controlId="isRemember">
                <Col sm={{ span: 8, offset: 3 }} className="text-md-start">
                  <Form.Check label="Remember me"
                    checked={data.isRemember}
                    onChange={handleChange('isRemember')} />
                </Col>
              </Form.Group>
              <div className="row mb-3">
                <div className="col-6"><Link to="/forgot">Forgot password</Link></div>
                <div className="col-6"><Link to="/signup">New account</Link></div>
              </div>
              <Button className="w-100 btn btn-lg btn-primary"
                type="button"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                <span className="px-2">Log in</span>
              </Button>
            </Form>
          </div>
          <div className="col-md-3 col-sm-12 order-3"></div>
        </div>

      </main>
    </>
  );
}

export default Login;
