import { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Col, Button, Form, FormControl, InputGroup, FormLabel, Spinner,
} from 'react-bootstrap';
import { emailPattern, namePattern } from '../common/constants';
import useAuth from '../hooks/useAuth';

import './signup.css';

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : '/console';
}

function Signup() {
  const title = 'Signup';
  const context = useOutletContext();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { addUser, getSession } = useAuth();
  const [user, setUser] = useState(getSession())
  const navigate = useNavigate();
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();
  // Watch the value of the password field
  const password = watch('password');

  useEffect(() => {
    if (user) {
      navigate(redirectPath(search));
    }
  }, [])

  const handleSignup = async (data) => {
    try {
      setIsLoading(true);
      const newUser = await addUser(data);
      // eslint-disable-next-line no-console
      console.log(`signup successful, user: ${newUser}`);
      context.setAlertOpts({
        text: 'Signup successful',
        icon: 'success',
        timer: 5000,
      });
      setIsLoading(false);
      navigate('/login');
    } catch (err) {
      context.setAlertOpts({
        text: err.message,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-signup mt-5 pt-5">
        <Form className="row g-2 card rounded-3 bg-tr-dark p-4" noValidate>
          <p className="fw-normal text-center">Fill up the form and then click <strong>Sign up</strong> button to sign up.</p>
          <Form.Group as={Col} lg="12" controlId="inputFullName">
            <FormLabel>Full Name</FormLabel>
            <FormControl type="text"
              isInvalid={errors.fullname}
              placeholder="Full Name"
              {
              ...register('fullname', {
                required: true,
                pattern: namePattern,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullname?.type === 'required' && 'Full name is required'}
              {errors.fullname?.type === 'pattern' && 'No special characters allowed except hyphen'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputEmail">
            <FormLabel>Email</FormLabel>
            <FormControl type="email"
              isInvalid={errors.email}
              placeholder="Email@domain.com"
              {
              ...register('email', {
                required: true,
                pattern: emailPattern,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.type === 'required' && 'Email is required'}
              {errors.email?.type === 'pattern' && 'Invalid email'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="inputPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="text"
              isInvalid={errors.phone}
              placeholder="Phone Number"
              {...register('phone', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone && 'Phone Number is required'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputPassword">
            <FormLabel>Password</FormLabel>
            <FormControl type="password"
              isInvalid={errors.password}
              placeholder="Password"
              {
              ...register('password', {
                required: true,
                minLength: 5,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.type === 'required' && 'Password is required'}
              {errors.password?.type === 'pattern' && 'Password must be at least 5 characters long'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputConfirmPassword">
            <FormLabel>Confirm Password</FormLabel>
            <FormControl type="password"
              isInvalid={errors.confirmPassword}
              placeholder="Confirm Password"
              {
              ...register('confirmPassword', {
                required: true,
                minLength: 5,
                validate: (value) => (
                  value === password || 'Passwords do not match'
                ),
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              label="I agree to receive any marketing promotional materials from Big Boss Generation."
              feedback="You must agree before submitting."
              feedbackType="invalid"
              isInvalid={errors.agree}
              {...register('agree', { required: true })}
            />
          </Form.Group>
          <Form.Group className="my-3">
            <Form.Check
              label={<>I hereby confirmed that I have read, understand and agree to the&nbsp;
                <a href="/tnc/Terms-of-Use" target="_blank">Terms of Use</a>,&nbsp;
                <a href="/tnc/Disclaimer" target="_blank">Disclaimer</a> and&nbsp;
                <a href="/tnc/Privacy-Policy" target="_blank">Privacy Policy</a>.</>}
              feedback="You must agree before submitting."
              feedbackType="invalid"
              isInvalid={errors.agreeTnC}
              {...register('agreeTnC', { required: true })}
            />
          </Form.Group>
          <Button className="w-100 btn btn-lg btn-primary"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit(handleSignup)}
          >
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Sign up</span>
          </Button>
        </Form>
      </main>
    </>
  );
}

export default Signup;
