import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import Footer from '../components/Footer';
import MainNavbar from '../components/MainNavbar';
import './main.css';

function MainLayout() {
  const { pathname } = useLocation();
  const [alertOpts, setAlertOpts] = useState({})

  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };

  useEffect(() => {
    if (alertOpts?.text && alertOpts.text !== '') {
      Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        ...alertOpts,
      })
    }
  }, [alertOpts]); // Run effect whenever the pathname changes

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname]); // Run effect whenever the pathname changes

  return (
    <>
      <MainNavbar />
      <Outlet context={{ setAlertOpts }} />
      <Footer />
    </>
  );
}

export default MainLayout;
