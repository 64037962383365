import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

function Tnc() {
  const location = useLocation();
  const { md } = useParams();

  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(`/md/${md}.md`)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>
      <main className="container-fluid">
        <div className="px-4 py-5 my-5 text-justify">
          <div className="col-lg-6 mx-auto card text-dark p-4 md-body">
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        </div>
      </main>
    </>
  );
}

export default Tnc;
