import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Home() {
  const title = 'Big Boss Generation';

  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        // Handle error if the video doesn't play, such as user interaction required
        console.error('Video playback failed:', error);
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid fade-in">
        <div className="px-4 py-0 text-center home-content">
          <div className="col-12 mx-auto">
            <div className='row vh-100 fs-1'>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-end mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/about')}
                >ABOUT</div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-center mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/buy')}
                >APPLY</div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-5 glow-link">
                <div className='align-self-md-center align-self-sm-start mb-5 home-link text-chaman'
                  onClick={() => navigate('/contact')}
                >CONTACT</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
