import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './btn.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import img1 from './images/about1.jpg';
import img2 from './images/about2.jpg';
import img3 from './images/about3.jpg';
import img4 from './images/about4.jpg';

const images = [
  img1,
  img2,
  img3,
  img4,
];

function About() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-4 mt-5 row">

          <div className='col-sm-12 text-md-start text-center px-5 order-1'>
            <h1 className="display-5 fw-bold py-4 text-chaman">About</h1>
          </div>

          <div className='col-md-5 col-sm-12 pt-0 px-md-5 order-md-2 order-3'>
            <p className="lead mb-4 fs-5 text-md-start text-center text-white pr-5">
              Welcome to Big Boss Generation Club, where luxury meets innovation. Located in the heart of Hong Kong, we redefine nightlife with a seamless blend of exclusivity and cutting-edge technology. Our mission is to offer unparalleled experiences through our tokenized platform, granting access to elite membership tiers and premium services. Join us on a journey to elevate your entertainment experience, surrounded by elegance and prestige.
            </p>
          </div>

          <div className="col-md-7 col-sm-12 pt-0 order-md-3 order-2">
            <div className="slideshow-container p-3 p-md-4 rounded-3 bg-tr-dark">
              <Slide arrows={false}>
                {images.map((i) => (
                  <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${i})` }}>
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>
        <div className="px-1 px-md-5 py-2 my-0 row">
          <div className='mx-md-3 mx-sm-0 col-12 text-center text-md-start px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>
            <h1 className="display-5 fw-bold py-4 text-chaman">Membership</h1>
            <p className="lead mb-4 fs-5 text-white pr-5">
              Join the Big Boss Generation community and unlock a world of exclusive benefits. Our membership program offers three distinct levels, each designed to enhance your nightlife experience with personalized services and VIP access. Become part of an elite circle that enjoys unique events, priority reservations, and more.
              <br />
              <button className='button-gold my-3 px-3 py-2 rounded-pill'
                onClick={() => navigate('/blockchain')}
              > BUY NOW </button>
            </p>
          </div>
        </div>
        <div className="w-100" style={{ height: '98px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default About;
