import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './btn.css';

function Blockchain() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Blockchain</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-0 px-md-4 pt-4  pt-md-4 mt-5 row">
          <div className='col-md-12 col-sm-12 text-left p-md-5 p-sm-1'>
            <h1 className="display-5 fw-bold py-4 text-chaman">Blockchain</h1>
            <h2 className="fw-bold py-4  text-chaman">Membership Utility</h2>
            <div className="row d-flex" id="bc-table">
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">

                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header">
                    &nbsp;
                  </div>
                  <ul className="list-group list-group-flush text-end">
                    <li className="list-group-item">Holding Token<span className="d-none d-md-inline"> in wallet</span></li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">Application required</span>
                      <span className="d-inline d-md-none">App req'd</span>
                    </li>
                    <li className="list-group-item">Referral</li>
                    <li className="list-group-item">Quota</li>

                    <li className="list-group-item fw-bold pt-4" style={{ minHeight: '68px' }}>Benefits</li>
                    <li className="list-group-item">Priority<span className="d-none d-md-inline"> Entry</span></li>
                    <li className="list-group-item"><span className="d-none d-md-inline">Includes</span> Guest</li>
                    <li className="list-group-item">Private Lounge<span className="d-none d-md-inline"> access</span><sup>1</sup></li>
                    <li className="list-group-item">Private Lounge<span className="d-none d-md-inline"> access</span><sup>2</sup></li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">Private events @BBC</span>
                      <span className="d-md-none d-inline">BBC Events</span>
                    </li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">Special event Invitations</span>
                      <span className="d-md-none d-inline">Special event</span>
                    </li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">Personalized Concierge</span>
                      <span className="d-md-none d-inline">Personalized<br/>Concierge</span>
                    </li>
                  </ul>
                  <div className='card-footer'>
                  </div>
                </div>

              </div>
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header">
                    <h3 className="card-title text-center text-bronze text-chaman">BRONZE</h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$50,000</li>
                    <li className="list-group-item border-bottom">Yes</li>
                    <li className="list-group-item border-bottom">No</li>
                    <li className="list-group-item border-bottom">UNLIMITED</li>

                    <li className="list-group-item border-bottom  pt-4" style={{ minHeight: '68px' }}>Elite</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item "><i className="bi bi-x-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-bronze my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > Buy </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header">
                    <h3 className="card-title text-center text-silver text-chaman">SILVER</h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$200,000</li>
                    <li className="list-group-item border-bottom">Yes</li>
                    <li className="list-group-item border-bottom">Yes</li>
                    <li className="list-group-item border-bottom">UNLIMITED</li>

                    <li className="list-group-item border-bottom  pt-4" style={{ minHeight: '68px' }}>Prestige</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item "><i className="bi bi-x-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-silver my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > Buy </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex px-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header">
                    <h3 className="card-title text-center text-gold text-chaman">GOLD</h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$500,000</li>
                    <li className="list-group-item border-bottom">Yes</li>
                    <li className="list-group-item border-bottom">Yes</li>
                    <li className="list-group-item border-bottom">LIMITED 100</li>

                    <li className="list-group-item border-bottom  pt-4" style={{ minHeight: '68px' }}>Infinity</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item"><i className="bi bi-check-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-gold my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > Buy </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 px-md-5 py-2 my-0 row">
          <div className='mx-md-3 mx-sm-0 col-12 text-justify px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>
            <h1 className="display-5 fw-bold py-4 text-chaman text-center text-md-start">Blockchain Regristration </h1>
            <p className="lead mb-4 fs-5 text-center text-md-start text-white pr-5" style={{ backgroundColor: '#0000' }}>
              Embrace the future of entertainment with our blockchain-based token system. Built on the Solana network, our tokens offer secure and efficient transactions. Register today to gain access to exclusive membership tiers and unlock premium services. Experience the perfect blend of luxury and technology at Big Boss Generation Club.<br />
              <button className='button-gold my-3 px-5 py-2 rounded-pill'
                onClick={() => navigate('/signup')}
              > Register </button>
            </p>
          </div>
        </div>
        <div className="w-100" style={{ height: '498px', backgroundColor: '#0000' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default Blockchain;
