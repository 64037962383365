import { NavLink, useNavigate } from 'react-router-dom';

// import './navbar.css';
import logo from './boss-logo.png';

function MainNavbar() {
  const navigate = useNavigate();

  const items = [
    { path: '/coming-events', title: 'Upcoming Events' },
    { path: '/blockchain', title: 'Blockchain' },
    { path: '/login', title: 'Member' },
  ];

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container-fluid">

        <div className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={logo} alt="Company Logo" className="me-3 img-fluid logo" />
          <div className="d-flex flex-column fs-5">
            <span className="fw-bold text-chaman">Big Boss</span>
            <span className="text-chaman">Generation</span>
          </div>
        </div>
        <button className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" style={{ color: 'var(--bright)', paddingTop: '3px' }} ><i className="bi bi-list"></i></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav">
            {
              items.map((item, i) => (
                <li key={i} className="button border rounded-pill nav-item p-1 me-2 mb-1"
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelectorAll('.navbar-toggler')?.[0]?.click()
                }}
                >
                  <NavLink className="nav-link" to={item.path}>{item.title}</NavLink>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbar;
