import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './btn.css';

function Contact() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-5 mt-5 row">
          <div className='mx-md-5 mx-sm-0 col-12 text-justify px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>

            <h1 className="display-5 fw-bold py-4 text-chaman">Background</h1>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              We’re here to assist you. Reach out to us via our contact form or find us at our club location.<br/>Our dedicated team is ready to provide any information you need to enhance your BBoss experience.
            </p>
            <h3 className="fw-bold pt-4">Email</h3>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              <a href="mailto:inquiry@bigbossgeneration.com">askme@bigbossgeneration.com</a>
            </p>
            <h3 className="fw-bold pt-4">Address</h3>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              Mandarin Plaza, 14 Science Museum Rd, Tsim-Sha-Tsui
            </p>

          </div>
        </div>
        <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default Contact;
